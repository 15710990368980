/*================================================
  Global CSS
=================================================*/

@import-normalize;
@import url("https://fonts.googleapis.com/css2?family=Roboto:wght@100;300;400;500;600;700;800&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Montserrat:wght@400;500;800&display=swap");
@import "~bootstrap/scss/bootstrap";

html[data-theme="light"] {
  --backgroundImg: url("/img/bg-w.jpg");
  --backgroundLogo: url("/img/new-logo.png");
  --backgroundLamp: url("/img/bg-lamp.svg");
  --themeColor: #3e4042;
  --firmColor: #ffffff;
  --footerBg: #fafafa;
}

html[data-theme="dark"] {
  --backgroundImg: url("/img/bg-b.jpg");
  --backgroundLogo: url("/img/new-logo.png");
  --backgroundLamp: url("/img/bg-lamp-white.svg");
  --themeColor: #ffff;
  --firmColor: #56595c;
  --footerBg: #000;
}

:root {
  --fontFamily: "Roboto";
  --montFontFamily: "Montserrat";
  --footerBtnTextColor: #181818;
  --firmWhiteColor: #fff;
  --firmGreenColor: #607db7;
  --firmYellowColor: #ffc736;
  --lightGreenColor: #9be650;
  --darkGreenColor: #37591f;
  --greyColor: #858a8f;
  --fontColor: var(--themeColor);
  --fontSize: 24px;
  --lineHeight: 24px;
  --h1FontSize: 96px;
  --h2FontSize: 48px;

  @media (max-width: 768px) {
    --fontSize: 16px;
    --h1FontSize: 42px;
    --h2FontSize: 36px;
  }
}

#root {
  overflow-x: hidden;
  overflow-y: hidden;
}

body {
  margin: 0;
  padding: 0;
  line-height: var(--lineHeight);
  color: var(--fontColor);
  // background-image: var(--backgroundImg);
  background-size: 100% 100%;
  background-repeat: no-repeat;
  // background-color: #2f3133;
  background-color: var(--footerBg);
  transition: background 200ms linear;
  font: {
    size: var(--fontSize);
    family: var(--fontFamily);
  }
}

a {
  color: inherit;
  text-decoration: none;
  font-family: var(--fontFamily);
  text-decoration: underline;
  text-decoration-color: initial;
  font-weight: 600;

  &:hover {
    // text-decoration: underline;
    // text-decoration-color: initial;
    font-weight: 800 !important;
  }
}

h1,
h2,
h3 {
  margin: 0;
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

.logo {
  background: var(--backgroundLogo);
  background-position: top center;
  background-size: contain;
  background-repeat: no-repeat;
  width: 110px;
  height: 73px;
  transition: background 200ms linear;
}

.line {
  margin-bottom: 10px;
  width: 139px;
  height: 8px;
  background-image: linear-gradient(#56595c, #56595c), linear-gradient(to left top, transparent 50%, #56595c 0%);
  background-repeat: no-repeat;
  background-size: 90px 90px;
  background-position: 0%, 90px;

  &:before {
    content: "";
    margin: 0;
    display: block;
    width: 59px;
    height: 8px;
    background-image: linear-gradient(var(--firmGreenColor), var(--firmGreenColor)),
      linear-gradient(to left top, transparent 50%, var(--firmGreenColor) 0%);
    background-repeat: no-repeat;
    background-size: 30px 30px;
    background-position: 0%, 30px;
  }
}

.loader {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 30px;
  padding: 50px 0;

  .text-success {
    color: #607db7 !important;
  }
}

.main-bg.active {
  .bg_img {
    background-image: none;
  }
  .home {
    background-image: none;
  }
}

/*================================================
    SwitchTheme styles 
 =================================================*/

.tumbler-wrapper {
  margin-left: auto;
  width: 80px;
  height: 35px;
  background-color: #56595c;
  border: darken(#aae2f1, 40);
  border-radius: 30px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0 6px;
  cursor: pointer;
  display: flex;
  position: relative;
  user-select: none;

  svg {
    width: 15px;
    height: 15px;
  }
}

.tumbler {
  position: absolute;
  height: 25px;
  width: 25px;
  border-radius: 50%;
  background-color: #fff;
  transition:
    transform 0.5s,
    background-color 0.5s;
  will-change: transform;
}

.tumbler.night-mode {
  transform: translateX(calc(100% + 20px));
}

.mont-block {
  background-image: url("../img/mont-white-bg.png");
  width: 100%;
  height: 100%;
  top: 0;
  position: absolute;
  background-size: cover;
  background-repeat: no-repeat;
  z-index: -1;
}

/*================================================
    Title styles 
 =================================================*/

.heading {
  margin-bottom: 20px;
  h2 {
    font-family: var(--montFontFamily);
    font-style: normal;
    font-weight: 800;
    max-width: 900px;
    font-size: 96px;
    line-height: 103px;
    color: var(--fontColor);
  }
  h3 {
    font-family: var(--montFontFamily);
    font-style: normal;
    font-weight: 800;
    max-width: 945px;
    font-size: 48px;
    line-height: 56px;
    margin-bottom: 12px;
  }
}

.title {
  line-height: 103px;
  font-size:  var(--h2FontSize);
  font-weight: 800;
  font-family: var(--montFontFamily);
  margin-bottom: 32px;
  @media (max-width: 401px) {
    font-size: 36px;
    line-height: 120%;
  }
}

.type {
  font-weight: 500;
  max-width: 900px;
  font-size: 20px;
  line-height: 32px;
  margin-bottom: 120px;

  img {
    margin-right: 12px;
  }
}

/*================================================
    No-page styles 
 =================================================*/

.no-page {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  min-height: calc(100vh - 25px);

  &__error {
    margin-bottom: 20px;
    span {
      color: rgb(167, 3, 3);
    }
  }
  &__btn {
    font-size: 24px;
    line-height: 24px;
  }
}

/*================================================
    Modal styles 
 =================================================*/

.modal-body {
  display: flex;
  gap: 10px;
  color: #56595c;
}

.pad {
  min-height: 100vh;
  width: 100%;
  position: absolute;
  left: 0;
  top: 0;
  z-index: 3;
}

@media screen and (max-width: 991px) {
  .heading {
    h2 {
      font-size: 76px;
      line-height: 83px;
    }
  }
}

@media screen and (max-width: 767px) {
  .heading {
    h2 {
      font-size: 56px;
      line-height: 63px;
    }
    h3 {
      font-size: 38px;
      line-height: 46px;
    }
  }

  .type {
    font-size: 16px;
    margin-bottom: 60px;
  }
}

@media screen and (max-width: 510px) {
  .heading {
    h2 {
      font-size: 44px;
      line-height: 53px;
    }
    h3 {
      font-size: 36px;
      line-height: 44px;
    }
  }
}

@media screen and (max-width: 410px) {
  .heading {
    h2 {
      font-size: 33px;
      line-height: 46px;
    }
    h3 {
      font-size: 27px;
      line-height: 36px;
    }
  }
  .type {
    margin-bottom: 30px;
  }
}

//new styles
.styleList {
  list-style: none;
}
.styleNum {
  font-weight: 600;
  font-size: 24px;
  color: #ebc74d !important;
  margin: 5px 10px 0 0;
}
.styleText {
  max-width: 600px;
  font-size: 16px;
}
.styleIt {
  margin-bottom: 10px;
}

//
//
// #root {
//   background-image: url(./../img/bg.jpg);
//   background-repeat: no-repeat;
//   background-size: cover;
// }
//
.bg_img {
  z-index: -1;
  position: absolute;
  top: 0;
  right: 0;
  left: 0;
  height: 600px;
  background-image: url(../img/bg-b.jpg);
  background-size: cover;
  background-position: center;
}

.video_bg {
  position: relative;
  width: 100%;
  height: 100vh; /* Встановіть бажану висоту відеофону */
  overflow: hidden;
}

.video_bg video {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  object-fit: cover; /* Зробить відео розтягнутим на всю висоту та ширину */
  z-index: -1; /* Розташує відео позаду контенту */
}

.vision {
  position: relative;
  user-select: none;
  cursor: pointer;
  font-size: 20px;
  color: #858a8f;
  font-weight: 400;
  line-height: 38px;

  &::before {
    content: url(./../../public/img/eye.png);
    position: absolute;
    left: -30px;
    top: 4px;
  }
}

.advantages {
  &__cards {
    margin-bottom: 100px;
    display: flex;
    justify-content: space-between;

    @media (max-width: 1024px) {
      flex-direction: column;
    }
  }

  &__card {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;

    &--title {
      font-family: var(--montFontFamily);
      font-size: var(--lineHeight);
      font-weight: 700;
      text-align: center;
      max-width: 248px;
      margin-bottom: 17px;
    }

    &--text {
      text-align: center;
      max-width: 272px;
      font-size: var(--fontSize);
    }
  }
}

.contactAs {
  padding: 40px 35px 65px;
  text-decoration: none;
  display: block;
  width: 100%;
  height: 302px;
  background-image: linear-gradient(to right, rgba(37, 163, 252, 0.88), rgba(37, 163, 252, 0.88)),
    url(../img/Advantages/contact.svg);
  background-position: right;
  background-repeat: no-repeat;
  background-size: cover;
  color: #fff;
  font-family: var(--montFontFamily);
  font-size: calc(var(--fontSize) * 2);

  @media (max-width: 1024px) {
    height: 200px;
    font-size: calc(var(--fontSize) * 1.5);
    padding: 20px 15px;
  }

  &__wrapper {
    display: flex;
    height: 100%;
    flex-direction: column;
    justify-content: space-between;
  }

  &__title {
    height: 35px;
    font-family: var(--montFontFamily);
    font-size: calc(var(--fontSize) * 2);
    font-weight: 700;

    @media (max-width: 1024px) {
      height: 200px;
      font-size: calc(var(--fontSize) * 1.5);
    }
  }

  &__content {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  &__left {
    width: 100%;
    font-family: var(--fontFamily);
    font-size: calc(var(--fontSize) + 6px);
    min-height: 120px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    font-weight: 700;

    @media (max-width: 1360px) {
      font-size: calc(var(--fontSize));
    }
  }

  &__right {
    font-weight: 700;
    width: 100%;
    font-family: var(--fontFamily);
    font-size: calc(var(--fontSize) + 6px);
    min-height: 120px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: flex-start;

    @media (max-width: 1360px) {
      font-size: calc(var(--fontSize));
    }
  }
}

.services {
  &__cards {
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
    height: 383px;
    gap: 40px;
    margin-bottom: 300px;

    @media (max-width: 1200px) {
      flex-wrap: nowrap;
      height: max-content;
    }

    @media (max-width: 500px) {
      margin-bottom: 76px;
    }
  }

  &__card {
    padding: 0 100px 0 10px;
    position: relative;
    border-radius: 4px;
    width: 505px;
    background-color: #56595c;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    text-decoration: none;
    height: 101px;
    font-weight: 400;
    font-size: 24px;
    font-family: var(--fontFamily);
    color: #ffffff;
    transition: all 0.3s ease;
    box-shadow: 5px 5px 15px 0px #262829;

    @media (max-width: 1200px) {
      width: 100%;
      height: 100px;
    }

    @media (max-width: 768px) {
      font-size: 16px;
      padding: 0 10px 0 10px;
    }

    &--icon {
      margin-right: 20px;
    }
    cursor: auto;
  }

  &__cont {
    margin-bottom: 100px;
  }
}

.contactAs-2 {
  padding: 40px 35px;
  text-decoration: none;
  display: block;
  width: 100%;
  height: 302px;
  background-image: linear-gradient(to right, rgba(119, 192, 67, 0.79), rgba(119, 192, 67, 0.79)),
    url(../img/Advantages/contact.svg);
  background-position: right;
  background-repeat: no-repeat;
  background-size: cover;
  color: #fff;
  font-family: var(--montFontFamily);
  font-size: calc(var(--fontSize) * 2);
  margin-bottom: 50px;

  @media (max-width: 1024px) {
    height: 200px;
    font-size: calc(var(--fontSize) * 1.5);
    padding: 20px 15px;
  }

  &__text {
    font-weight: 400;
    font-size: calc(var(--fontSize) + 6px);

    @media (max-width: 1024px) {
      font-size: var(--fontSize);
    }
  }
}

.contactAs-3 {
  min-height: 300px;
  padding: 50px 192px 50px 40px;
  background-image: url(./../img/contactAs-3.svg);
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;

  @media (max-width: 768px) {
    padding: 50px 40px 50px 40px;
  }

  &__title {
    margin-bottom: 25px;
    font-size: 32px;
    font-family: var(--montFontFamily);
    font-weight: 800;
  }

  &__content {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    gap: 20px;
  }

  &__texts {
    font-weight: 700;
    font-family: var(--fontFamily);
    font-size: 24px;
    width: 100%;
    display: flex;
    justify-content: space-between;

    @media (max-width: 1024px) {
      flex-direction: column;
      justify-content: space-between;
    }
  }

  &__hover {
    color: #fff;
    transition: color 0.3s ease;
    cursor: pointer;
  }

  &__hover:hover {
    color: #607db7;
  }
}

.about {
  color: #fff;


  &__title {
    padding: 75px 33px 55px 37px;
    background-color: #607db7;
    font-family: var(--montFontFamily);
    font-weight: 800;
    font-size: 48px;
    line-height: 56px;
    min-height: 300px;
    margin-bottom: 20px;
    @media (max-width: 768px) {
      font-size: 24px;
    }
  }

  &__links {
    display: flex;
    justify-content: space-between;
    margin-bottom: 80px;
    gap: 50px;

    @media (max-width: 1360px) {
      flex-direction: column;
      align-items: center;
    }
  }

  &__link {
    position: relative;
    width: 50%;
    height: 200px;
    display: flex;
    align-items: center;
    padding: 60px 24px;
    background-color: #56595c;
    line-height: 40px;
    font-weight: 800;
    text-decoration: none;
    font-size: 32px;
    font-family: var(--montFontFamily);
    margin-bottom: 50px;

    &::after {
      position: absolute;
      content: url(./../../public/img/about/link.svg);
      top: 24px;
      right: 24px;
    }

    @media (max-width: 1360px) {
      width: 100%;
    }

    @media (max-width: 1024) {
      font-size: var(--fontSize);
    }
  }

  &__text-1 {
    font-size: var(--fontSize) + 8px;
    line-height: 32px;
    font-weight: 400;
    font-family: var(--fontFamily);
    color: var(--themeColor);
  }

  &__cards {
    display: flex;
    justify-content: space-between;
    gap: 12px;
    width: 100%;
    margin-bottom: 90px;

    @media (max-width: 1400px) {
      margin: 0 auto;
      flex-wrap: wrap;
      justify-content: space-between;
      width: 600px;
      margin-bottom: 90px;
    }

    @media (max-width: 1024px) {
      margin: 0 auto;
      width: 100%;
      flex-direction: column;
      align-items: center;
      justify-content: space-between;
      margin-bottom: 90px;
    }
  }

  &__card {
    width: 100%;
    min-height: 370px;
    background-color: #607db7;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
    gap: 20px;
    border-radius: 4px;

    @media (max-width: 1400px) {
    }

    @media (max-width: 1024px) {
      width: 100%;
    }

    &--title {
      text-align: center;
      font-family: var(--montFontFamily);
      font-weight: 700;
      font-size: 24px;
      line-height: 30px;
      height: 50px;
    }

    &--text {
      height: 100px;
      text-align: center;
      font-family: var(--montFontFamily);
      font-weight: 600;
      font-size: 16px;
      line-height: 20px;
    }

    &--img {
      width: 200px;
      height: 200px;
      background-position: center;
      background-size: cover;
      background-repeat: no-repeat;
    }

    &--img-1 {
      background-image: url(./../img/about/1.svg);
    }
    &--img-2 {
      background-image: url(./../img/about/2.svg);
    }
    &--img-3 {
      background-image: url(./../img/about/3.svg);
    }
    &--img-4 {
      background-image: url(./../img/about/4.svg);
    }
  }
}

// .steps {
//   margin-bottom: 84px;
//   width: 100%;
//   display: flex;
//   justify-content: space-between;

//   &__one {
//     padding: 0 0 0 30px;
//     justify-content: flex-start !important;
//     height: 332px;
//     width: 33%;
//     background-image: url(../img/Vector-1.png);
//     background-position: center;
//     background-size: 100% 100%;
//     background-repeat: no-repeat;
//   }

//   &__two {
//     height: 332px;
//     width: 33%;
//     background-image: url(../img/Vector-2.png);
//     background-position: center;
//     background-repeat: no-repeat;
//     background-size: 100% 100%;
//   }
//   &__three {
//     padding: 0 50px 0 0;
//     justify-content: flex-end !important;
//     height: 332px;
//     width: 33%;
//     background-image: url(../img/Vector-3.png);
//     background-position: center;
//     background-size: 100% 100%;
//     background-repeat: no-repeat;
//   }
// }

// .step {
//   display: flex;
//   width: 100%;
//   justify-content: center;
//   align-items: center;

//   &__num {
//     margin-right: 10%;
//     font-size: 60px;
//     font-weight: 600;
//     font-family: var(--montFontFamily);
//   }
//   &__text {
//     line-height: 35px;
//     max-width: 30%;
//     font-size: 25px;
//     font-weight: 700;
//     font-family: var(--montFontFamily);
//   }
// }

.line-blue {
  width: 139px;
  height: 8px;
  background-image: linear-gradient(#56595c, #56595c), linear-gradient(to left top, transparent 50%, #56595c 0%);
  background-repeat: no-repeat;
  background-size: 90px 90px;
  background-position: 0%, 90px;

  &:before {
    content: "";
    margin: 0;
    display: block;
    width: 59px;
    height: 8px;
    background-image: linear-gradient(#607db7, #607db7), linear-gradient(to left top, transparent 50%, #607db7 0%);
    background-repeat: no-repeat;
    background-size: 30px 30px;
    background-position: 0%, 30px;
  }
}

.line-red {
  width: 139px;
  height: 8px;
  background-image: linear-gradient(#56595c, #56595c), linear-gradient(to left top, transparent 50%, #56595c 0%);
  background-repeat: no-repeat;
  background-size: 90px 90px;
  background-position: 0%, 90px;

  &:before {
    content: "";
    margin: 0;
    display: block;
    width: 59px;
    height: 8px;
    background-image: linear-gradient(#c84630, #c84630), linear-gradient(to left top, transparent 50%, #c84630 0%);
    background-repeat: no-repeat;
    background-size: 30px 30px;
    background-position: 0%, 30px;
  }
}

.arr {
  &__text-1,
  &__text-2,
  &__text-3 {
    width: 600px;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    font-family: var(--montFontFamily);
    font-size: 32px;
    line-height: 40px;
    font-weight: 700;
    position: relative;
    left: 15%;
  }
}

.partners {
  &__arrows {
    // padding: 0 5% 0 8%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    height: 334px;
    margin-bottom: 82px;
    background-position: center;
    background-size: cover;

    &--red {
      background-image: url(./../img/partner__arr.svg);
    }

    &--blue {
      background-image: url(./../img/partner__arr-blue.svg);
    }
  }

  &__contacts {
    margin-bottom: 60px;

    &--title {
      font-size: var(--h2FontSize);
      font-family: var(--montFontFamily);
      line-height: 56px;
      font-weight: 800;
      margin-bottom: 10px;
    }

    &--text {
      max-width: 736px;
      font-weight: 400;
      font-size: var(--fontSize);
      font-family: var(--fontFamily);
      margin-bottom: 20px;
      line-height: 32px;
    }

    &--subtitle {
      font-weight: 700;
      font-size: 32px;
      font-family: var(--fontFamily);
      margin-bottom: 20px;
    }

    &--content {
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      max-width: 700px;
      height: 200px;
      background-color: #56595c;
      padding: 30px 25px;
      line-height: 30px;
    }
  }

  &__example {
    margin-bottom: 50px;
    &--title {
      font-family: var(--montFontFamily);
      font-size: var(--h2FontSize);;
      font-weight: 800;
      line-height: 56px;
      margin-bottom: 20px;
    }

    &--link {
      margin-bottom: 20px;
      font-size: var(--fontSize);
      position: relative;
      left: 50px;
      font-weight: 400;
      font-family: var(--fontFamily);

      &::before {
        content: url(./../img/file.svg);
        position: absolute;
        left: -45px;
        top: 2px;
      }
    }
  }

  &__form {
    &--title {
      font-family: var(--montFontFamily);
      font-size: 48px;
      font-weight: 800;
      line-height: 56px;
      margin-bottom: 32px;
    }
    &--blue {
      background-color: #607db7;
      margin-bottom: 250px;
    }

    &--red {
      background-color: #c84630;
      margin-bottom: 250px;
    }
  }
}

.pForm {
  width: 100%;
  padding: 65px 35px;

  &__title {
    font-size: 36px;
    line-height: 40px;
    font-family: var(--fontFamily);
    margin-bottom: 150px;
  }

  &__block-1 {
    gap: 31px;
    display: flex;
    justify-content: space-between;
    margin-bottom: 36px;

    @media (max-width: 768px) {
      flex-direction: column;
    }
  }

  &__block-2 {
    gap: 31px;
    display: flex;
    justify-content: space-between;
    margin-bottom: 36px;
    @media (max-width: 768px) {
      flex-direction: column;
    }
  }

  &__block-3 {
    gap: 31px;
    display: flex;
    justify-content: space-between;
    margin-bottom: 36px;
    @media (max-width: 768px) {
      flex-direction: column;
    }
  }

  &__block-4 {
    gap: 31px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    @media (max-width: 768px) {
      flex-direction: column;
    }
  }

  &__text {
    width: 50%;

    &--wrapper {
      height: 48px;
      width: 100%;
      display: flex;
      justify-content: space-between;
      align-items: center;
      gap: 16px;
    }

    &--num {
      display: flex;
      align-items: center;
      justify-content: center;
      font-size: 12px;
      font-family: var(--fontFamily);
      font-weight: 500;
      border: 1px solid #fff;
      border-radius: 50%;
      min-width: 40px !important;
      height: 40px !important;

      @media (max-width: 993px) {
        min-width: 30px !important;
        height: 30px !important;
      }
    }

    &--num-2 {
      min-width: 40px !important;
      height: 40px !important;

      @media (max-width: 993px) {
        min-width: 30px !important;
        height: 30px !important;
      }
    }

    &--text {
      min-width: 120px;
      font-size: 16px;
      font-family: var(--fontFamily);
      height: 30px;
      display: flex;
      justify-content: center;
      align-items: center;
    }

    &--text-2 {
      min-width: 160px;
      font-size: 16px;
      font-family: var(--fontFamily);
      height: 30px;
      display: flex;
      justify-content: center;
      align-items: center;
    }

    &--line {
      height: 1px;
      width: 90%;
      background-color: #fff;
    }

    &--none {
      width: 100%;
    }
  }

  &__inputs {
    width: 50%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    gap: 16px;

    @media (max-width: 768px) {
      width: 100%;
    }
  }

  &__input {
    height: 48px;
    padding-left: 11px;
    font-size: 16px;
    color: #292929;
    width: 100%;

    &::placeholder {
      font-size: 16px;
      color: #858abf;
    }
  }

  &__textArea {
    height: 144px;
    padding-left: 11px;
    font-size: 16px;
    color: #292929;
    width: 100%;
    line-height: 48px;

    &::placeholder {
      font-size: 16px;
      color: #858abf;
      line-height: 48px;
    }
  }

  &__footer {
    width: 50%;
    position: relative;
    left: 50px;
    font-size: 12px;
    line-height: 16px;
    font-weight: 400;
    font-family: var(--fontFamily);
  }

  &__button {
    width: 50%;

    @media (max-width: 768px) {
      width: 100% !important;
    }
  }

  &__button button {
    width: 304px;
    height: 48px;
    background-color: #56595c;
    font-size: 21px;
    font-family: var(--fontFamily);
    font-weight: 500;
    display: flex;
    align-items: center;
    justify-content: center;
    color: #fff;
    border: none;
    outline: none;

    &:hover {
      background-color: #43474b;
    }

    &:active {
      background-color: #43474b;
    }
  }
}

.line__blue {
  margin-bottom: 10px;
  width: 139px;
  height: 8px;
  background-image: linear-gradient(#56595c, #56595c), linear-gradient(to left top, transparent 50%, #56595c 0%);
  background-repeat: no-repeat;
  background-size: 90px 90px;
  background-position: 0%, 90px;

  &:before {
    content: "";
    margin: 0;
    display: block;
    width: 59px;
    height: 8px;
    background-image: linear-gradient((#607db7), #607db7), linear-gradient(to left top, transparent 50%, #607db7 0%);
    background-repeat: no-repeat;
    background-size: 30px 30px;
    background-position: 0%, 30px;
  }
}

.line__red {
  margin-bottom: 10px;
  width: 139px;
  height: 8px;
  background-image: linear-gradient(#56595c, #56595c), linear-gradient(to left top, transparent 50%, #56595c 0%);
  background-repeat: no-repeat;
  background-size: 90px 90px;
  background-position: 0%, 90px;

  &:before {
    content: "";
    margin: 0;
    display: block;
    width: 59px;
    height: 8px;
    background-image: linear-gradient((#c84630), #c84630), linear-gradient(to left top, transparent 50%, #c84630 0%);
    background-repeat: no-repeat;
    background-size: 30px 30px;
    background-position: 0%, 30px;
  }
}

/*===============================================
  Map component styles
================================================*/

.land {
  cursor: pointer;
  &:hover {
    fill: url(#paint0_linear_258_1025);
    fillOpacity: 0.8;
  }
}

.map-picker {
  margin-bottom: 50px;

  h3 {
    font-size: 24px;
    font-style: normal;
    font-weight: 400;
    line-height: 32px;
  }

  &__svg {
    display: flex;
    align-items: center;
    justify-content: center;
  }
}

.oper-picker {
  display: flex;
  flex-direction: column;
  position: relative;
  top: -150px;
  max-width: 475px;

  .radiobtn {
    color: var(--themeColor);
    &::before {
      content: url(../img/radiomap.svg);
      top: 3px;
    }
  }

  input.circl:checked + label:before {
    content: url(../img/radiomap-active.svg);
    top: 3px;
  }

  input.circl:checked + label {
    color: #607db7;
  }
}

.tarif__description {
  color: var(--themeColor);
  font-size: 24px;
  font-style: normal;
  font-weight: 400;
  line-height: 32px;
  margin-bottom: 90px;

  span {
    color: #607db7;
    font-size: 16px;
    line-height: 32px;
    display: block;
  }
}

.news__inner {
  margin-bottom: 100px !important;
}

.news-container {
  border-radius: 3px 0 0 3px;
  width: 1120px;
  height: 750px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.news-cont {
  position: relative;
  padding-left: 27px;
  background-color: #56595c;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  gap: 50px;
  width: 100%;
  height: 150px;
  border-radius: 0px 0px 3px 3px !important;
}

.news-time {
  font-size: 24px !important;
  line-height: 28px !important;
  font-family: var(--fontFamily) !important;
  font-weight: 400 !important;
  text-decoration: none !important;
  color: #ffffff !important;
}

.news-title {
  text-decoration: 0 !important;
  font-size: 24px !important;
  font-weight: 600 !important;
  font-family: var(--fontFamily) !important;
  line-height: 28px;
  margin: 0 !important;
  color: #ffffff !important;
}

.news__inner {
  width: 1120px;
  height: 750px;
}

.lazy-load-image-background.blur.lazy-load-image-loaded > img {
  // min-width: 1120px;
  // height: 600px;
  opacity: 1;
  transition: opacity 0.3s;
  border-radius: 3px 3px 0px 0px !important;
}

.news a img {
  border-radius: 0 !important;
}

.img-news-item {
  border-radius: 0 !important;
}

.news-description {
  position: absolute;
  left: 27px;
  bottom: 5px;
  display: flex;
  height: 50px;
  align-items: center;
  justify-content: space-between;
  width: 250px;
}

.news-desc-1,
.news-desc-2 {
  &::before {
    content: none;
  }
  text-align: left;
  color: #858a8f;
  font-size: 16px;
  font-weight: 400;
  font-family: var(--fontFamily);
}

.asd {
  display: flex;
  margin-bottom: 80px;

  @media (max-width: 768px) {
    margin-bottom: 50px;
  }
}

.asd-1 {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 450px;
  background-image: url(./../img/asd1.svg);
  background-position: left;
  background-size: contain;
  background-repeat: no-repeat;
}

.asd-2 {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 450px;
  width: 100%;
  background-image: url(./../img/asd-b.svg);
  background-position: center;
  background-size: contain;
  background-repeat: no-repeat;
}
.asd-3 {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 450px;
  background-image: url(./../img/asd3.svg);
  background-position: right;
  background-size: contain;
  background-repeat: no-repeat;
}

.asd-11 {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 450px;
  background-image: url(./../img/asd11.svg);
  background-position: left;
  background-size: contain;
  background-repeat: no-repeat;
}

.asd-22 {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 450px;
  width: 100%;
  background-image: url(./../img/asd-r.svg);
  background-position: center;
  background-size: contain;
  background-repeat: no-repeat;
}
.asd-33 {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 450px;
  background-image: url(./../img/asd33.svg);
  background-position: right;
  background-size: contain;
  background-repeat: no-repeat;
}
@media (max-width: 400px) {
  .asd-1,
  .asd-2,
  .asd-3,
  .asd-11,
  .asd-22,
  .asd-33 {
    height: 100px;
  }
  
}
.asd-cont {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 11px;
}

.asd-num {
  font-weight: 700;
  font-size: 5vw;
}
.asd-text {
  font-weight: 500;
  line-height: 2.6vw;
  font-size: 2.5vw;
}

.fm-blue {
  background-color: #607db7 !important;
}
.fm-red {
  background-color: #c84630 !important;
}

.element-animation {
  display: none !important;
}
.client-info {
  &__list {
    padding: 0;
    list-style: none;
  }

  &__item {
    display: flex;
    justify-content: flex-start;
    align-items: flex-start;

    &__number {
      position: relative;
      top: 12px;
      font-weight: 700;
      color: #fff;
      font-size: 50px;
      margin-right: 10px;
    }
    
    @media (max-width:500px) {
      margin-right: 10px;
      font-size: 25px;
      
    }
  }
}

.services {
  &__title2 {
    font-family: var(--montFontFamily);
    font-size: 48px;
    font-weight: 800;
    line-height: 56px;
    margin-bottom: 47px;

    @media (max-width: 500px) {
      font-size: 32px;
    }
  }
}

.ss {
  display: flex;
  justify-content: space-between;
  flex-direction: row;
  width: 100%;
  margin-bottom: 90px;

  @media (max-width: 992px) {
    flex-direction: column;
  }

  &__card {
    position: relative;
    padding: 20px;
    text-decoration: none;
    width: 542px;
    height: 200px;
    background-color: #607DB7;
    display: flex;
    align-items: center;
    justify-content: center;
    background-size: 40px 40px;
    border-radius: 4px;
    font-family: var(--montFontFamily);
    font-size: 32px;
    font-weight: 800;
    line-height: 40px;


    @media (max-width: 1200px) {
      width: 400px;
    }

    @media (max-width: 992px) {
      width: 100%;
      margin-bottom: 50px;
    }

    @media (max-width: 600px) {
      font-size: 24px;
    }

    
  }
  
  &__bg {
    position: absolute;
    min-width: 40px;
    min-height: 40px;
    background-image: url(./../img/qwerty.png);
    background-size: 40px 40px;
    background-position: center;
    background-repeat: no-repeat;
    top: 20px;
    right: 20px;
  }
}
.heading h2 {font-size: var(--h1FontSize);}
.heading h3 {font-size: var(--h2FontSize);}
  .content {
    color: var(--themeColor);
    font-size: var(--h2FontSize) !important;
    margin-top: 0 !important;
  }
