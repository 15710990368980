.header {
  margin-bottom: 70px;
  margin-top: 25px;
  position: relative;
  z-index: 4;

  .container {
    position: relative;
  }

  &__icons {
    display: flex;
    gap: 20px;
    z-index: 3;
  }

  &__company,
  &__client {
    font-size: 22px;
    line-height: 26px;
    position: relative;
    cursor: pointer;
    z-index: 20;

    :hover {
      color: #0d6efd;
    }
  }
  &__company {
    color: var(--firmGreenColor);
  }

  .search-svg {
    margin-right: 15px;
    
    svg {
      width: 25px;
      height: 25px;
        path{
          transition: all 0.3s;
        }
    }

    &:hover {
      svg path {
        fill: var(--firmGreenColor);
      }
    }
  }
  .search-svg.active {
    svg path {
      fill: var(--firmGreenColor);
    }
  }
}


.eye-inner {
  position: relative;
  display: inline-block;

  .eye-descr {
    clip: rect(0,0,0,0);
    opacity: 0;
    font-size: 16px !important;
    position: absolute;
    bottom: -50px;
    transform: translateX(-60%);
    transition: all 0.3s;
    width: 150px;
  }

  &:hover {
    .eye-descr {
      clip: auto;
      opacity: 1;
    }
    .eye-svg {
      path {
        fill: var(--firmGreenColor);
      }
    }
  }
}

.eye-svg {
  width: 32px;
  height: 32px;
  margin-left: 15px;
  cursor: pointer;

  path {
    transition: all 0.3s;
    fill: var(--greyColor);
  }
}

.eye {
  transform: translateY(75%);
  transition: all 0.3s;
  position: absolute;
  z-index: 3;
  opacity: 0;
  clip: rect(0,0,0,0);
}

.eye.active {
  transform: translateY(-15%);
  position: relative;
  clip: auto;
  opacity: 1;
}

.eye~.prefooter {
  margin-top: -80px;
}

.eye.active~.prefooter {
  margin-top: 25px;
}

.eye-panel {
  width: 100%;
  padding: 30px 100px;
  border: 2px solid var(--greyColor);
  background-color: #8e9399;
  display: flex;
  gap: 10px;
  flex-wrap: wrap;
  justify-content: space-between;
  border-radius: 0px 0px 3px 3px;
  position: relative;
  z-index: 10;

  .eye-inner {
    display: flex;
    align-items: center;
    gap: 10px;
    user-select: none;
    cursor: pointer;

    &:hover {
      .eye-svg {
        path {
          fill: #eeeeee;
        }
      }
    }
  }

  .eye-svg {
    path {
      fill: #FFFFFF;
    }
  }

  .eye-common {
    color: #FFFFFF;
    p {
      margin-bottom: 0;
    }
  }
}

.eye-font, 
.eye-color {
  display: flex;
  align-items: center;
  gap: 5px;
  p {
    color: #FFFFFF;
    margin-right: 5px;
    margin-bottom: 0;
  }
}

.eye-font {
  .eye-font-a.active {
    border: 5px solid #2F3133;
  }
}

.eye-color {  
  .eye-font-a.active {
    background-color: #2F3133;
    color: #FFFFFF;
    border: 5px solid #FFFFFF;
  }
}

.eye-font-a {
  user-select: none;
  cursor: pointer;
  background-color: #FAFAFA;
  color: #3E4042;
  padding: 5px 10px;
}

.header.company ~ .home {
  .main-slide h1 {
    text-align: right;
  }
}

.header.client ~ .home {
  .main-slide h1 {
    text-align: left;
  }
}

.menu-map {
  display: flex;
  align-items: center;
  right: -8%;
  top: -15px;
  cursor: pointer;
  user-select: none;
  position: relative;

  @media (max-width: 768px) {
    right: -25px;
    
  }
}

.header__company {
  top: -15px;
  user-select: none;

  .header__company-svg path{
    // position: relative;
    // right: -5%;
    fill: var(--themeColor);
  }

  &:hover {
    .header__company-svg path {
      fill: #fff;
    }
    .nav-btn {
      color: #fff;
    }
  }
}

.header__company-svg--right {
  position: relative;
  right: -5%;
  transform: rotate(180deg);
}

.header__company-svg--left {
  left: 0;
}

.header__company-svg.right {
  // transform: rotate(180deg);
}

.nav-btn {
  background-color: transparent;
  border: none;
  color: var(--themeColor);
  width: calc(100% - 7px);
}

.nav-btn.company {
  // position: relative;
  text-align: left;
  // left: -18%;
}

.nav-btn.client {
  position: relative;
  right: -18px;
  text-align: right;
}

.top-navigation {
  min-width: 510px;
  width: 28%;
  background-color: #2f3133;
  // padding: 110px 100px 50px 120px;
  position: absolute;
  opacity: 0;
  top: 0px;
  transition: all 0.3s;
  z-index: 15;

  a {
    text-decoration: none;
    font-size: var(--fontSize);
  }

  &__close {
    cursor: pointer;
    position: absolute;
    background-color: #2f3133;
    padding: 15px;
    width: 62px;
    height: 62px;
  }
}

.top-navigation.company.active ~ .header__inner .top-navigation.client.active ~ .header__inner .header__company {
  &:hover {
    .header__company-svg path {
      fill: #fafafa;
    }
    .nav-btn {
      color: #fafafa;
    }
  }
}

.top-navigation.company {
  padding: 50px 64px 48px;
  // height: 1100px;
  // width: 504px;
  left: -100%;
  top: -25px;
}

.top-navigation.company.active {
  left: 0;
  opacity: 1;

  .top-navigation__close {
    right: -60px;
    top: 15px;
  }
}

.top-navigation.client {
  padding: 50px 64px 48px;
  // height: 1100px;
  // width: 504px;
  right: -100%;
  top: -25px;
}

@media (max-width: 990px) {
  .top-navigation.client {
    display: none;
  }
}

.top-navigation.client.active {
  right: 0;
  opacity: 1;

  .top-navigation__close {
    left: -60px;
    top: 15px;
  }
}

.tabs {
  &__title {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 45px;
    margin-bottom: 55px;

    a {
      font-family: var(--fontFamily);
      font-weight: 500;
      font-size: 19px;
      line-height: 21px;
      text-transform: uppercase;
      position: relative;
      padding: 0;
      cursor: pointer;

      &:hover {
        text-decoration: none;
      }

      &::after {
        content: "";
        position: absolute;
        top: 25px;
        left: 0;
        width: 0;
        height: 3px;
        border-radius: 1px;
        transition: all 0.3s;
      }
    }
    &-electro.active {
      &::after {
        background: var(--firmGreenColor);
        width: 100%;
      }
    }
    &-gaz.active {
      &::after {
        background: var(--firmYellowColor);
        width: 100%;
      }
    }

    &-electro {
      color: var(--firmGreenColor) !important;
      &:hover::after {
        background: var(--firmGreenColor);
        width: 100%;
      }
      &:focus {
        color: var(--firmGreenColor) !important;
      }
    }
    &-gaz {
      color: var(--firmYellowColor) !important;
      &:hover::after {
        background: var(--firmYellowColor);
        width: 100%;
      }
      &:focus {
        color: var(--firmYellowColor) !important;
      }
    }
  }
  &__electro {
    .tabs__button-link:hover {
      a {
        color: #607DB7;
      }
      .tabs__button-svg {
        path {
          fill: #607DB7;
        }
      }
    }
    .tabs__personal-login {
      border: 1px solid var(--firmWhiteColor);
      color: var(--firmWhiteColor);
      &:hover {
        background-color: var(--firmWhiteColor);
        border: 1px solid #fff;
      }
      .tabs__personal-svg path {
        fill: #fff;
      }
    }
    .tabs__personal-link {
      &:hover {
        svg path {
          fill: #fff;
        }
      }
      a:hover {
        color: #fff;
      }
    }
  }

  &__gaz {
    .tabs__button-link:hover {
      a {
        color: var(--firmWhiteColor);
      }
      .tabs__button-svg {
        path {
          fill: var(--firmWhiteColor);
        }
      }
    }
    .tabs__personal-login {
      border: 1px solid var(--firmWhiteColor);
      color: var(--firmWhiteColor);
      // &:hover {
      //   border: 1px solid var(--firmYellowColor);
      //   background-color: var(--firmYellowColor);
      // }
      .tabs__personal-svg path {
        fill: var(--firmWhiteColor);
      }
    }
    // .tabs__personal-link {
    //   &:hover {
    //     svg path {
    //       fill: var(--firmYellowColor);
    //     }
    //   }
    //   a:hover {
    //     color: var(--firmYellowColor);
    //   }
    // }
  }

  &__button-link {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 10px;
    cursor: pointer;
    a {
      font-family: var(--fontFamily);
      text-transform: uppercase;
      display: flex;
      flex-direction: column;
      gap: 5px;
      align-items: center;
      font-weight: 700;
      font-size: 16px;
      line-height: 40px;
    }
    .tabs__button-svg {
      path {
        fill: #fafafa;
      }
    }
  }

  &__button-inner {
    display: flex;
    // gap: 95px;
    margin-bottom: 55px;
    color: #fafafa;
  }

  &__personal {
    display: flex;
    flex-direction: column;
    gap: 10px;
    justify-content: flex-start;
    font-size: 18px;
    line-height: 21px;
    color: #fafafa;
  }

  &__personal-link {
    display: flex;
    gap: 9px;
    align-items: center;
    text-transform: uppercase;
    margin-bottom: 12px;
    line-height: 0;

    svg {
      width: 18px;
      height: 18px;
    }
  }

  &__personal-login {
    display: flex;
    justify-content: space-between;
    align-items: center;
    border-radius: 4px;
    transition: all 0.3s;
    position: relative;

    a {
      display: block;
      width: 100%;
      padding: 10px 18px;

      &:hover {
        color: #2F3133;
        text-decoration: none;
      }
    }

    &:hover {
      color: #2F3133;
    }
  }

  &__personal-svg {
    position: absolute;
    right: 18px;
    cursor: pointer;
  }
}

.tabs__personal-login:hover {
  .tabs__personal-svg path {
    fill: #2F3133;
  }
}
.tabs__electro {
  .menu1__list-item,
  .menu2__list-item {
    a:hover {
      // color: var(--firmGreenColor);
      color: #607DB7;
    }
  }
}

.tabs__gaz {
  .menu1__list-item,
  .menu2__list-item {
    a:hover {
      // color: var(--firmYellowColor);
      color: #C84630;
    }
  }
}

.menu1__list,
.menu2__list {
  padding: 0;
  margin-bottom: 50px;
}

.menu2__list {
  // height: 500px;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
}
.company .menu2__list {
  text-align: left;
}

.client .menu2__list {
  text-align: right;
}
.menu1__list-item,
.menu2__list-item {
  list-style: none;
  margin-bottom: 10px;
  font-family: var(--fontFamily);
  font-weight: 500;
  font-size: 16px;
  line-height: 19px;
  color: #fafafa;
  button {
    background-color: transparent;
    border: none;
    padding: 0;
    margin: 0;
    color: #fafafa;
  }
}

.menu2__list-item {
  margin-bottom: 25px;
}

.menu2__list-item.drop {
  position: relative;
  a:hover {
    color: #fafafa;
  }
}

.dropdown-content {
  display: none;
  position: absolute;
  top: 0;
  left: 170px;
  min-width: 70px;
  z-index: 1;
}

.menu2__list-item:hover {
  .dropdown-content {
    display: flex;
    flex-direction: column;
    gap: 8px;
    font-size: 14px;
    line-height: 16px;
    color: #858a8f;
  }
  .link-arrow {
    transform: rotate(180deg);
  }
}

.link-arrow {
  margin-left: 10px;
}

.tabs__gaz {
  .tabs__button-link:hover p {
    color: #C84630;
  }
  .menu2__list-item:hover {
    button {
      color: #C84630;
    }
    .link-arrow {
      path {
        fill: #C84630;
      }
    }
  }
}
.tabs__personal-login:hover {
  background-color: #fafafa !important;
  }
.tabs__electro {
  .menu2__list-item:hover {
    button {
      color: var(--firmGreenColor);
    }
    .link-arrow {
      path {
        fill: var(--firmGreenColor);
      }
    }
  }
}

@media screen and (max-width: 1550px) {
  .top-navigation.client.active {
    // right: -1%;
  }
  .top-navigation.company.active {
    // left: -7%;
  }
}

@media screen and (max-width: 1400px) {
  .top-navigation {
    // padding: 110px 120px 50px 50px;
  }
}

@media screen and (max-width: 991px) {
  .header__company {
    display: none;
  }
  .header__inner {
    justify-content: space-between;
  }
  .vision {
    cursor: pointer;
    left: 50px;
    top: 7px;
    z-index: 12;
  }
}

@media screen and (max-width: 768px) {
  .header__icons,
  .logo__inner,
  .menu-map {
    width: 20%;
  }

  .client {
    right: -200%;
  }
  .top-navigation.company {
    left: -200%;
  }

  .header__inner {
    margin-right: 0;
  }
  
  .eye-panel {
    padding: 30px 30px;
  }
}
/////////////////////
.tabs__button-inner {
  justify-content: space-between;
}
