footer {
  font-weight: 400;
  font-size: 14px;
  padding: 40px 0;
  background: var(--footerBg);
  transition: background 200ms linear;
  p {
    font-weight: 400;
    font-size: 10px;
    line-height: 16px;
    color: var(--greyColor);
  }
  a {
    padding: 0 8px;
  }
}

#footer {
  padding-top: 100px;
}

.myLine {
  background-color: #4C5054;
  width: 100%;
  height: 1px;
  margin: 30px 0 15px;
}
.partners {
  img {
    filter: grayscale(100%);
    transition: all 0.2s;
    height: 46px;
    &:hover {
      filter: grayscale(0);
    }
  }
}

.download-inner {
  a {
    &:hover {
      .download-svg path {
        fill: var(--firmGreenColor);
      }
    }
  }
}

.description {
  margin-top: 20px;
}

.prefooter {
  background: var(--footerBg);
  transition: background 200ms linear;
  position: relative;
  z-index: 2;
  transition: all 0.3s;

  a {
    font-weight: 500;
    font-size: 12px;
    color: var(--themeColor);
  }

  ul {
    margin: 0;
    padding: 0;
    li {
      list-style: none;
      padding: 0;
      a {
        color: var(--greyColor);
        line-height: 10px;
      }
    }
  }

  h3 {
    font-weight: 500;
    font-size: 24px;
    line-height: 28px;
    color: var(--greyColor);
    margin-bottom: 5px;
  }
}

h3.both {
  font-weight: 500;
  font-size: 24px;
  line-height: 28px;
  color: var(--firmWhiteColor);
  margin: 8px 0;
  margin-bottom: 15px;

  &:after {
    content: "";
    height: 2px;
    background: var(--firmWhiteColor);
    display: block;
    margin-top: 8px;
  }
}

h3.electro {
  font-weight: 500;
  font-size: 24px;
  line-height: 28px;
  color: var(--firmGreenColor);
  margin: 8px 0;
  margin-bottom: 15px;

  &:after {
    content: "";
    height: 2px;
    background: var(--firmGreenColor);
    display: block;
    margin-top: 8px;
  }
}

h3.gaz {
  font-weight: 500;
  font-size: 24px;
  line-height: 28px;
  color: var(--firmYellowColor);
  margin: 8px 0;
  margin-bottom: 15px;

  &:after {
    content: "";
    height: 2px;
    background: var(--firmYellowColor);
    display: block;
    margin-top: 8px;
  }

  h3 {
    color: var(--firmYellowColor);
    margin-bottom: 5px;
  }
}

.btn-white {
  background: var(--firmWhiteColor);
  border-radius: 3px;
  font-weight: 700;
  font-size: 14px;
  line-height: 16px;
  align-items: center;
  text-align: center;
  color: var(--footerBtnTextColor) !important;
  display: block;
  padding: 5px 0;
  text-decoration: none;
  &:hover {
    font-weight: 900;
  }
}
.btn-grin {
  background: var(--firmGreenColor);
  border-radius: 3px;
  font-weight: 700;
  font-size: 14px;
  line-height: 16px;
  align-items: center;
  text-align: center;
  color: #fafafa;
  display: block;
  padding: 5px 0;
}
.btn-yellow {
  background: var(--firmYellowColor);
  border-radius: 3px;
  font-weight: 700;
  font-size: 14px;
  line-height: 16px;
  align-items: center;
  text-align: center;
  color: #fafafa;
  display: block;
  padding: 5px 0;
}

.borderline {
  margin-bottom: 25px;
  &::after,
  &::before {
    content: "";
    height: 1px;
    display: block;
    background: var(--greyColor);
    margin: 10px calc(var(--bs-gutter-x) * 0.5);
    width: calc(100% - var(--bs-gutter-x) * 0.5);
  }
}
.footer-head {
  margin-top: 60px;
}
.third:after {
  content: "";
  height: 1px;
  display: block;
  background: var(--greyColor);
  margin-top: 10px;
}
.menu-nav .col-4 a {
  color: var(--greyColor);
  margin-bottom: 20px;
  display: block;
}

@media screen and (max-width: 1200px) {
  .foot-info,
  .foot-recv {
    width: 100%;
    margin-bottom: 0 !important;
  }

  .foot-recv {
    margin-top: 0 !important;
  }
  .partners {
    img {
      margin-bottom: 5px;
    }
  }
  .download-app {
    p {
      line-height: 30px;
    }
  }
}

@media screen and (max-width: 767px) {
  .download-app {
    max-width: 70%;
    p {
      line-height: 18px;
    }
  }
  .download-inner {
    max-width: 30%;
    margin-bottom: 15px;
    min-width: 95px;
    text-align: right !important;
  }
  .footer-chat {
    max-width: 20%;
    text-align: left !important;
  }
  .theme-inner {
    max-width: 25%;
  }
}

@media screen and (max-width: 475px) {
  .download-app {
    max-width: 30%;
  }
}
